body {
  color: #0F3F62;
  background-color: #F7FAFC;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-action > div > button{
  font-size: 1.1rem!important;
}

.badge-success {
  color: #0e6245 !important;
  background-color: #cbf4c9 !important;
}
.badge-warning {
  color: #983705 !important;
  background-color: #F8E5D0 !important;
}

.MuiButton-textPrimary{
  background-color: #5469d4!important;
  color: white!important;
}
.MuiButton-textPrimary.Mui-disabled{
  background-color: rgba(84, 105, 212, 0.26) !important;
  color: white!important;
}

.MuiButton-textSecondary{
  color: #E02020!important;
}
.MuiButton-text.badge-success.Mui-disabled{
  color: #0e6245 !important;
  background-color: rgba(203, 244, 201, 0.26) !important;
}